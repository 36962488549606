import React, { memo } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { Flex } from 'native-base';

import { useGetAsset } from 'Hooks';

import { IconBaseComponent } from 'Components/utility/IconBaseComponent';

import i18n from 'Utilities/i18n';

interface Props {
  requirements: Requirement[];
}

const PasswordRequirements: React.FC<Props> = ({ requirements }) => {
  const { getAssetInColorScheme } = useGetAsset();
  const checked = getAssetInColorScheme(
    'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.password.icons.FilledSuccess'
  );
  const unchecked = getAssetInColorScheme(
    'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.password.icons.CircleActionsSuccess'
  );

  const iconError = i18n.t('Global.microcopy.common.IconError');

  return (
    <Flex mb={2}>
      {requirements.map((requirement) => {
        return (
          <Flex direction="row" alignItems="center" mt={2} key={requirement.data.key}>
            <IconBaseComponent
              tintColor={
                requirement.isInvalid
                  ? 'alert.warning.50.active.default'
                  : requirement.match
                  ? 'alert.success.50.active.default'
                  : 'neutral.40'
              }
              nativeID={`PasswordRequirements.${requirement.data.key}`}
              alt="error"
              iconImage={
                requirement.isInvalid ? iconError : requirement.match ? checked : unchecked
              }
              size={5.5}
              mr={2}
              ml={1}
            />
            <Text
              nativeID={`Login.content.signInForm.formField.password.helpText.${requirement.data.key}`}
              fontSize="s"
              right={2}
              lineHeight="2xl"
              color={
                requirement.isInvalid
                  ? 'alert.warning.50.active.default'
                  : requirement.match
                  ? 'text.60'
                  : 'neutral.40'
              }
              fontFamily="labelBaseDefault"
              fontWeight="labelBaseDefault"
            >
              {i18n.t<string>(
                `Login.content.signInForm.formField.password.helpText.${requirement.data.key}`,
                {
                  count: requirement.data.value,
                }
              )}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default memo(PasswordRequirements);
